import * as React from 'react';
import { useAuth } from './lib/auth-provider';
import { CustomSpinner } from './components/common/Spinner';
import './assets/css/Main.css';

const AuthenticatedApp = React.lazy(() => import('./authenticated-app'));
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'));

function App() {
  const { user } = useAuth();
  return (
    <React.Suspense fallback={<CustomSpinner />}>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  );
}
export default App;

import React from 'react';
import { render } from 'react-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './lib/auth-provider';
import { customTheme } from './theme/index';
import Fonts from './theme/fonts';
import App from './app';

import './i18n';

export const queryClient = new QueryClient();

function Tailoor() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <ChakraProvider theme={customTheme}>
      <Fonts />
      <QueryClientProvider client={queryClient}>
        <Router>
          <AuthProvider>
            <App />
          </AuthProvider>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ChakraProvider>
  );
}

const rootElement = document.getElementById('root');
render(<Tailoor />, rootElement);

// Scroll fix Chrome on Windows
const htmlElement = document.querySelector('html');
htmlElement.style.overflowY = 'scroll';

const localStorageKey = '__auth_token__';
const apiURL = process.env.REACT_APP_API_URL;

class UserObj {
  name: string;

  email: string;

  language: string;

  role: Array<string>;

  tokenExpiry: number;

  constructor(
    name: string,
    email: string,
    language: string,
    role: Array<string>,
    tokenExpiry: number,
  ) {
    this.name = name;
    this.email = email;
    this.language = language;
    this.role = role;
    this.tokenExpiry = tokenExpiry;
  }
}

const getAuthToken = () => window.localStorage.getItem(localStorageKey);

const setAuthToken = (tokenValue: string) => {
  window.localStorage.setItem(localStorageKey, tokenValue);
};

const deleteAuthToken = () => {
  window.localStorage.removeItem(localStorageKey);
};

const getBusinessToken = async (action: string, customerId?: number) => {
  const token = getAuthToken();
  if (!token) return;

  const response = await window.fetch(`${apiURL}/auth/token/`, {
    method: 'POST',
    body: action === 'new' ? JSON.stringify({ customerId }) : null,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    // something went wrong(token expired?), force logout
    deleteAuthToken();
    window.location.reload();
  }
  const data = await response.json();
  window.localStorage.setItem(localStorageKey, data.token);
};

const parseAuthToken = (token = getAuthToken()) => {
  try {
    const tokenData = JSON.parse(atob(token?.split('.')[1]!));
    const userObj: UserObj = new UserObj(
      tokenData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
      tokenData[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'
      ],
      'it-IT',
      tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
      parseInt(tokenData.exp, 10),
    );
    return userObj;
  } catch (e) {
    return null;
  }
};
const handleRefreshToken = () => {
  const token = getAuthToken();
  if (!token) return undefined;
  // update refresh token every hour
  const { tokenExpiry } = parseAuthToken(token)!;
  const now = Date.now();
  const parsedExpiryTime = tokenExpiry * 1000;
  const refreshInterval = 60 * 60 * 1000; // 1 hr in millis
  // if auth token is about to expire in less than 1 hour ask for a refresh token
  const tokenNeedsRefresh = parsedExpiryTime - now <= refreshInterval;
  if (tokenNeedsRefresh) {
    getBusinessToken('refresh');
  }
  // set a refresh every hour anyway
  const updateRefreshToken = window.setInterval(
    () => getBusinessToken('refresh'),
    refreshInterval,
  );
  return updateRefreshToken;
};

export {
  getAuthToken,
  setAuthToken,
  deleteAuthToken,
  getBusinessToken,
  handleRefreshToken,
  parseAuthToken,
};

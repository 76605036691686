import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const i18nConfig = {
  fallbackLng: ['it'],
  supportedLngs: ['it'],
  // load: 'currentOnly',
  debug: process.env.NODE_ENV !== 'production',
  // have a common namespace used around the full app
  ns: ['translation', 'routes'],
  defaultNS: 'translation',
  interpolation: {
    escapeValue: false,
  },
  detection: {
    order: ['path', 'localStorage', 'cookie', 'navigator', 'htmlTag'],
  },
};

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(i18nConfig);

export default i18n;

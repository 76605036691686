import React from 'react';
import { Spinner, Flex } from '@chakra-ui/react';

export const CustomSpinner: React.FC = () => (
  <Flex
    minH="100vh"
    align="center"
    justify="center"
    position="absolute"
    top="0"
    right="0"
    bottom="0"
    left="0"
    zIndex="200"
    className="spinner"
  >
    <Spinner size="xl" />
  </Flex>
);
